import APIEvent from './APIEvent';
import R from './resource';
import * as util from './util';
//local static api response for development/demo only
import APIInfo from '../model/info-demo.json';
import APIAvail from '../model/avail-demo.json';
import APIBook from '../model/book-demo.json';

/**
 * class including core bookpoint functionality
 * @private
 * @hideconstructor
 */
class Core {
    constructor() {

        this.elements = [];

        this.state = {
            initialised: true,
            selected: {
                adults: 1,
                children: 0,
                arrival_date: util.formatDateISO(new Date()),
                departure_date: util.getTomorrowsDate(),
                duration: 1,
                quantity: 1,
                results: "all"
            },
            stage: {

            },
            guests: []
        };
        this.config = {
            api_version: "1.2.3",
        };
        this.api = {
            info: {
                data: undefined,
                running: false,
                prev: undefined
            },
            avail: {
                data: undefined,
                running: false,
                prev: undefined
            },
            book: {
                data: undefined,
                running: false,
                prev: undefined
            }
        };
        this.processor = {
            "default": {
                [R.string.und.event_api_info]: (info, state) => { },
                [R.string.und.event_api_avail]: (avail, state) => { }
            }
        };
    }

    /**
     * Apply changes to Core.state
     * @param {object} data 
     */
    setState(data) {

        let availRequired = false;
        //TODO: explore alternative comparison methods
        try {
            if (!!data.selected) {
                if (data.selected.adults !== this.state.selected.adults ||
                    data.selected.arrival_date !== this.state.selected.arrival_date ||
                    data.selected.children !== this.state.selected.children ||
                    data.selected.departure_date !== this.state.selected.departure_date ||
                    data.selected.duration !== this.state.selected.duration ||
                    data.selected.quantity !== this.state.selected.quantity ||
                    data.selected.rate_plan !== this.state.selected.rate_plan ||
                    data.selected.room_type !== this.state.selected.room_type) {
                    availRequired = true;
                }
            }
        }
        catch (e) {
            console.error(e);
        }
        const merged = { ...this.state, ...data };
        this.state = merged;
        if (availRequired) {
            this.getAvail();
        }
        //TODO: consider state change event when progress UI is implemented
        //state event fires after getAvail so widget can update with loading indicator
        this._dispatchEvent(R.string.und.event_state_changed, this.state, true);
    }

    /**
     * Dispatch {@link APIEvent} with given type and payload to all widgets and optionaly document
     * @private
     * @param {string} type 
     * @param {object} payload 
     * @param {bool} dispatchToDocument 
     */
    _dispatchEvent(type, payload, dispatchToDocument) {
        var event = new APIEvent(type, { [type]: payload });
        if (dispatchToDocument === true) {
            document.dispatchEvent(event);
        }
        this.elements.forEach((element) => {
            element.dispatchEvent(event);
        });
    }

    /**
     * @private
     * @param {string} html 
     */
    _stripHTML(html) {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    /**
     * @private
     * @param {string} text 
     */
    _capitalize(text) {
        return text.toLowerCase().replace(/\b\w/g, function (m) { return m.toUpperCase(); });
    }

    /**
     * @private
     * @param {object} stays
     */
    _formatAvailStays(staysJSON) {
        const sortedStays = Object.values(staysJSON).sort((a, b) => { a.total - b.total });
        return sortedStays;
        // let stays = sortedStays;
        // //TODO: find a better place to clean up the desc values coming out of API
        // stays.forEach((stay, policyIndex) => {
        //     stays[policyIndex].rate_plan_name = this._capitalize(stays[policyIndex].rate_plan_name);
        //     stays[policyIndex].rate.desc = this._stripHTML(stay.rate.desc);
        //     stays[policyIndex].policies.guarantee.desc = this._stripHTML(stay.policies.guarantee.desc);
        //     stays[policyIndex].policies.cancel.forEach((cancel, cancelIndex) => {
        //         stays[policyIndex].policies.cancel[cancelIndex].desc = this._stripHTML(cancel.desc);
        //     });
        // })
        // return stays;
    }

    /**
     * Make API call
     * @private
     * @param {string} url 
     * @param {JSON} body 
     */
    _fetchAPI(url, body) {
        const postBody = {
            "hotel_code": this.config.hotel_code,
            "token": this.config.token,
            "language": this.config.api_language,
            "version": this.config.api_version,
            ...body
        }
        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(postBody)
        }).then((response) => {
            return response.json();
        });
    }

    /**
     * Make API info call
     */
    getInfo() {

        if (this.config.demo === true) {
            return this.getInfoLocal();
        }

        // TODO: consider polling requests if already running
        // if (this.api.info.running === true) {
        // return Promise.resolve(this.api.info.data);
        // }

        this.api.info.running = true;
        const postData = {};
        return this._fetchAPI(`${this.config.api_root}/info`, postData).then((json) => {

            this.api.info.data = { ...json.hotel_descriptive_content[0] };
            // this.api.info.data = { ...json[0] };
            this.api.info.data.rooms = this.api.info.data.facility_info.guest_rooms.guest_room;

            if (!this.config.currency_symbol && this.api.info.data.currency_code) {
                this.config.currency_code = this.api.info.data.currency_code;
                this.config.currency_symbol = util.getCurrencySymbol(this.api.info.data.currency_code);
            }

            this.api.info.running = false;
            this._dispatchEvent(R.string.und.event_api_info, this.api.info.data, true);

            return this.api.info.data;

        }).catch((error) => {
            this.api.info.running = false;
            console.error("fetch info error", error);
            return null;
        });
    }

    /**
     * Make API avail call
     */
    getAvail() {

        if (this.config.demo === true) {
            return this.getAvailLocal();
        }

        const postData = {
            ...this.state.selected
        }

        // TODO: consider polling requests if already running
        //TODO: consider manage requests, cancel last and run latest
        // if (!requestIsNew || this.api.avail.running === true) {
        //     return Promise.resolve(this.api.avail.data);
        // }

        //TODO: will need a more rebust recording of when job is running.
        //TODO: running should be set earlier to make sure widget is correct
        this.api.avail.running = true;
        this.api.avail.prev = postData;
        this._fetchAPI(`${this.config.api_root}/avail`, postData).then((json) => {

            //process avail
            this.api.avail.data = json;
            if (this.api.avail.data.stays && this.api.avail.data.stays.stay.length) {
                this.api.avail.data.staysOrdered = this._formatAvailStays(this.api.avail.data.stays.stay);
            }

            //push avail into info
            try {
                for (let j = 0; j < this.api.info.data.rooms.length; j++) {
                    let infoRoom = this.api.info.data.rooms[j];
                    if (infoRoom.offer) {
                        break;
                    }
                    for (let i = 0; i < this.api.avail.data.staysOrdered.length; i++) {
                        let stay = this.api.avail.data.staysOrdered[i];
                        if (stay.room_type === infoRoom.code) {
                            infoRoom.offer = stay.total;
                            break;
                        }
                    }
                };
            }
            catch (error) {

            }

            this.api.avail.running = false;
            this._dispatchEvent(R.string.und.event_api_avail, this.api.avail.data, true);

            return this.api.avail.data;

        }).catch((error) => {
            this.api.avail.running = false;
            console.error("fetch avail error", error);
            return null;
        });
    }

    /**
     * Add HTMLElement to list of bookpoint widgets.
     * @param {HTMLElement} element 
     */
    addElement(element) {
        this.elements.push(element);
    }

    /**
     * Remove HTMLElement from list of bookpoint widgets.
     * @param {HTMLElement} element 
     */
    removeElement(element) {
        const result = this.elements.findIndex((singleElement) => {
            return singleElement === element;
        });
        if (result > -1) {
            this.elements.splice(result, 1);
        }
    }

    /**
     * When submit button is clicked. This function is called with dataset from button element.
     * @param {HTMLElement.dataset} dataset 
     */
    submit(dataset) {
        //TODO: check all data is present and correct
        switch (dataset.submit) {
            case "payment": {
                const formElem = document.querySelector(`#${dataset.id}-payment-form`);
                if (formElem) {
                    var formData = new FormData(formElem);
                    let guests = this.state.guests;
                    guests.push({
                        name: {
                            first_name: formData.get("contactFirstName"),
                            surname: formData.get("contactLastName")
                        },
                        telephone: {
                            phone_number: formData.get("contactPhone")
                        },
                        email: formData.get("contactEmail"),
                        // address: {
                        //     lines: [
                        //         formData.get("addressLineOne"),
                        //         formData.get("addressLineTwo"),
                        //     ],
                        //     city: formData.get("addressCity"),
                        //     country_code: formData.get("addressCountryCode"),
                        //     postcode: formData.get("addressPostcode")
                        // }
                    });

                    //must be set before widget stage_confirmation is rendered.
                    this.api.book.running = true;

                    this.setState({
                        guests: guests,
                        stage: {
                            ...this.state.stage,
                            [dataset.id]: "stage_confirmation"
                        }
                    });
                    return this.getBooking(formData);
                }
            }
        }
    }

    /**
     * Make API book call
     * @param {object} paymentDetails - Required payment details - stay, contact, address, card details
     */
    getBooking(paymentDetails) {

        //TODO: confirm all data is available

        if (this.config.demo === true) {
            return this.getBookLocal();
        }

        const selected = { ...this.state.selected }

        const postData = {
            res_status: "Commit",
            room_stays: {
                room_type: selected.room_type,
                rate_plan: selected.rate_plan,
                adults: selected.adults,
                children: selected.children
            },
            arrival_date: selected.arrival_date,
            duration: selected.duration,
            guests: this.state.guests,
            payment_card: {
                card_number: paymentDetails.get("paymentCardNumber"),
                expire_date: paymentDetails.get("paymentCardExpiration"),
                series_code: paymentDetails.get("paymentCardCVV"),
                card_holder_name: paymentDetails.get("paymentName")
            }
        }

        this.api.book.running = true;
        this._fetchAPI(`${this.config.api_root}/book`, postData).then((json) => {
            this.api.book.data = json;
            this.api.book.running = false;
            this._dispatchEvent(R.string.und.event_api_book, this.api.book.data, true);
        }).catch((error) => {
            this.api.book.running = false;
            console.error("fetch booking error", error);
            return null;
        });
    }

    /**
     * Local static copy of API info call. For development/demo only
     */
    getInfoLocal() {
        this.api.info.data = APIInfo.hotel_descriptive_content[0];
        this.api.info.data.rooms = this.api.info.data.facility_info.guest_rooms.guest_room;

        if (!this.config.currency_symbol && this.api.info.data.currency_code) {
            this.config.currency_code = this.api.info.data.currency_code;
            this.config.currency_symbol = util.getCurrencySymbol(this.api.info.data.currency_code);
        }

        this._dispatchEvent(R.string.und.event_api_info, this.api.info.data, true);
        return Promise.resolve(this.api.info.data);
    }


    /**
     * Local static copy of API avail call. For development/demo only
     */
    getAvailLocal() {
        this.api.avail.data = APIAvail;
        this.api.avail.data.staysOrdered = this._formatAvailStays(this.api.avail.data.stays.stay);
        this._dispatchEvent(R.string.und.event_api_avail, this.api.avail.data, true);
        return Promise.resolve(this.api.avail.data);
    }


    /**
     * Local static copy of API book call. For development/demo only
     */
    getBookLocal() {
        this.api.book.data = APIBook;
        this._dispatchEvent(R.string.und.event_api_book, this.api.book.data, true);
        return Promise.resolve(this.api.book.data);
    }
}

export default new Core();
