/**
 * Imports and compiles internal templates
 * @private
 * @module template
 */
import widget_base from './template/widget_base.pug';
import widget_price_cheapest from './template/widget_price_cheapest.pug';
import widget_price_range from './template/widget_price_range.pug';
import widget_select_date from './template/widget_select_date.pug';
import widget_select_extra from './template/widget_select_extra.pug';
import widget_list_extra from './template/widget_list_extra.pug';
import widget_stay_list from './template/widget_stay_list.pug';
import widget_room_stay_list from './template/widget_room_stay_list.pug';
import widget_stay_selected from './template/widget_stay_selected.pug';
import widget_avail_offer from './template/widget_avail_offer.pug';
import widget_room_avail_list from './template/widget_room_avail_list.pug';

const template = {
    widget: {
        base: widget_base,
        price_cheapest: widget_price_cheapest,
        price_range: widget_price_range,
        select_date: widget_select_date,
        select_extra: widget_select_extra,
        list_extra: widget_list_extra,
        stay_list: widget_stay_list,
        room_stay_list: widget_room_stay_list,
        stay_selected: widget_stay_selected,
        avail_offer: widget_avail_offer,
        room_avail_list: widget_room_avail_list,
    }
};

export default template;