/**
 * Resource module exports R
 * @private
 * @module resource
 * @example R.string.und.event_api_info
 */
const UND = {
    "event_api_info": "getInfo",
    "event_api_avail": "getAvail",
    "event_api_stay_selected": "onstayselected",
    "event_state_changed": "onstatechanged",
    "event_api_book": "getBook"
};
const R = {
    string: {
        "und": UND
    },
    getString(key, local) {
        if (!local) {
            local = "und";
        }
        if (this.string[local]) {
            if (!this.string[local][key]) {
                console.error("unknown string key");
                return "";
            }
            return this.string[local][key];
        }
        else {
            console.error("unknown local");
            return "";
        }
    }
}

export default R;