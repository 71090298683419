import template from './template.js';
import Core from './core';
import R from './resource';
import * as util from './util';

const WIDGET_SELECTOR = ".navarino-bookpoint-widget";
const DEFAULT_VALUE = {
    title: "Your booking",
    policy: false,
    action: false,
    actionValue: "Book now",
    actionOnclick: null,
    actionHref: "#",
    availability: false,
    extra: [],
    eventInfo: true,
    eventAvail: true,
    eventState: true,
    eventBook: true,
    selectedStay: null,
    widget_id: "default"
}

/**
 * Render widgets and listen for DOM events on widget elements.
 * @hideconstructor
 */
class Widget {

    /**
     * Initialise Widget class, Including initialising widgets via class name and adding "click" event listener
     * @private
     */
    init() {
        const elements = document.querySelectorAll(WIDGET_SELECTOR);
        [].forEach.call(elements, (singleElement) => {
            this.add(singleElement);
        });

        document.addEventListener("click", (event) => {
            //check the click event relates to a bookpont widget button
            //TODO: better identify bookpoint buttons (unique class name etc.)
            if (event.target.classList.contains("button")) {
                event.preventDefault();
                //update the state to reflect user input
                let newDataSet = { ...event.target.dataset };
                let newSelected = {
                    ...Core.state.selected,
                    ...newDataSet
                };
                if (newDataSet.roomType && !newDataSet.ratePlan) {
                    delete newSelected.ratePlan;
                }
                if (typeof newDataSet.nextStage === 'string') {
                    //this is a next stage button. update state
                    let newStage = { ...Core.state.stage };
                    newStage = {
                        ...Core.state.stage,
                        [newDataSet.id]: newDataSet.nextStage
                    };

                    //snakecase all object properties
                    //TODO: this should not be necassery. find a consistent use of API and dataset
                    for (var propertyName in newSelected) {
                        const snake = util.snakeCase(propertyName);
                        newSelected[snake] = newSelected[propertyName];
                        // if (snake != propertyName) {
                        //     delete newSelected[propertyName];
                        // }
                    }

                    //TODO: find better method of merging nested objects
                    Core.setState({
                        selected: newSelected,
                        stage: newStage
                    });
                }
                else if (typeof newDataSet.submit === 'string') {
                    Core.submit(newDataSet);
                }
            }
        });
    }

    /**
     * Create HTMLElement from raw html string
     * @private
     * @param {string} html 
     */
    _createElementFromHTML(htmlString) {
        var div = document.createElement('div');
        div.innerHTML = htmlString.trim();

        // Change this to div.childNodes to support multiple top-level nodes
        return div.firstChild;
    }

    /**
     * Builds widget HTML from template using config. Updates DOM using element.innerHTML
     * @private
     * @param {HTMLElement} element - Widget parent element
     * @param {object} config 
     */
    _buildWidget(element, config) {

        //allow developer to process api data before rendering by overriding processor function
        const infoExists = !!Core.api.info.data
        let infoProcessed = { ...Core.api.info.data };
        if (infoExists &&
            Core.processor[element.id] &&
            typeof Core.processor[element.id][R.string.und.event_api_info] === 'function') {
            Core.processor[element.id][R.string.und.event_api_info](infoProcessed, Core.state);
        }
        else if (infoExists &&
            Core.processor['default'] &&
            typeof Core.processor['default'][R.string.und.event_api_info] === 'function') {
            Core.processor['default'][R.string.und.event_api_info](infoProcessed, Core.state);
        }

        const availExists = !!Core.api.avail.data;
        let availProcessed = { ...Core.api.avail.data };
        if (!!availExists &&
            Core.processor[element.id] &&
            typeof Core.processor[element.id][R.string.und.event_api_avail] === 'function') {
            Core.processor[element.id][R.string.und.event_api_avail](availProcessed, Core.state);
        }
        else if (!!availExists &&
            Core.processor['default'] &&
            typeof Core.processor['default'][R.string.und.event_api_avail] === 'function') {
            Core.processor['default'][R.string.und.event_api_avail](availProcessed, Core.state);
        }

        const localConfig = {
            ...DEFAULT_VALUE,
            info: {
                running: Core.api.info.running,
                ...infoProcessed
            },
            avail: {
                running: Core.api.avail.running,
                ...availProcessed
            },
            book: {
                running: Core.api.book.running,
                ...Core.api.book.data
            },
            selected: Core.state.selected,
            currency_code: Core.config.currency_code,
            currency_symbol: Core.config.currency_symbol,
            date_locale: Core.config.date_locale,
            date_format: Core.config.date_format,
            text: Core.config.text,
            ...config
        };

        let widgetHTML = 'Navarino Bookpoint';

        if (typeof template.widget[localConfig.widget] === 'function') {
            widgetHTML = template.widget[localConfig.widget](localConfig);
        }
        else if (typeof template.widget['base'] === 'function') {
            console.error("no widget defined, using default");
            widgetHTML = template.widget['base'](localConfig);
        }
        else {
            console.error("no widget defined");
        }

        element.innerHTML = widgetHTML;
        this._updateStageFromState(element, config);
    }

    /**
     * Set Widget visibility by adding or removing class "visible"
     * @param {HTMLElement} element 
     * @param {bool} visible 
     */
    setVisibility(element, visible) {
        if (!element) {
            return;
        }
        if (visible !== true) {
            visible = false;
        }

        if (visible) {
            element.firstChild.classList.add("visible");
        }
        else {
            element.firstChild.classList.remove("visible");
        }
    }

    /**
     * Update a given widget's stage using Core.state
     * @private
     * @param {HTMLElement} element 
     * @param {object} config 
     */
    _updateStageFromState(element, config) {

        let nextStage = Core.state.stage[element.id] ? Core.state.stage[element.id] : "stage_info";

        // const elemMainContent = element.querySelector(".main-content");
        const elemStageInfo = element.querySelector(".stage_info");
        const elemStageAvail = element.querySelector(".stage_avail");
        const elemPayment = element.querySelector(".stage_payment");
        const elemConfirmation = element.querySelector(".stage_confirmation");

        if (elemStageInfo) elemStageInfo.style.display = "none";
        if (elemStageAvail) elemStageAvail.style.display = "none";
        if (elemPayment) elemPayment.style.display = "none";
        if (elemConfirmation) elemConfirmation.style.display = "none";

        switch (config.widget) {
            case "stay_selected": {

                if (nextStage === "stage_info" || nextStage === "stage_avail") {
                    elemStageAvail.style.display = "block";
                }
                else if (nextStage === "stage_payment") {
                    elemPayment.style.display = "block";
                }
                else if (nextStage === "stage_confirmation") {
                    elemConfirmation.style.display = "block";
                }
                break;
            }
            default: {

                if (nextStage === "stage_info") {
                    elemStageInfo.style.display = "block";
                }
                else if (nextStage === "stage_avail") {
                    elemStageAvail.style.display = "block";
                }
                else if (nextStage === "stage_payment") {
                    elemPayment.style.display = "block";
                }
                else if (nextStage === "stage_confirmation") {
                    elemConfirmation.style.display = "block";
                }
            }
        }
    }

    /**
     * Add bookpoint widget as child of selector element
     * @param {string|Element} selector - query selector string or DOM Element
     * @param {object} options 
     */
    add(selector, jsOptions) {

        let element = undefined;
        if (typeof selector === 'string') {
            element = document.querySelector(selector);
        }
        else if (typeof selector === 'object') {
            element = selector;
        }

        if (!element) {
            console.warn("Could not load element with arguments", { selector: selector, options: jsOptions })
            return;
        }

        let localDataset = {}
        Object.keys(element.dataset).forEach((key) => {
            if (element.dataset[key] === "true") {
                localDataset[key] = true;
            }
            else if (element.dataset[key] === "false") {
                localDataset[key] = false;
            }
            else {
                localDataset[key] = element.dataset[key];
            }
        });

        const config = {
            ...DEFAULT_VALUE, //const defaults
            ...localDataset, //user HTML data attrib
            ...jsOptions, // options passed in through Javascript
            widget_id: element.id
        };

        if (config.eventInfo) {
            element.addEventListener(R.string.und.event_api_info, (event) => {

                this._buildWidget(element, config);
                this.setVisibility(element, true);
            });
        }

        if (config.eventAvail) {
            element.addEventListener(R.string.und.event_api_avail, (event) => {

                this._buildWidget(element, config);
                this.setVisibility(element, true);
            });
        }

        if (config.eventState) {
            element.addEventListener(R.string.und.event_state_changed, (event) => {

                this._buildWidget(element, config);
                this.setVisibility(element, true);
            });
        }

        if (config.eventBook) {
            element.addEventListener(R.string.und.event_api_book, (event) => {

                this._buildWidget(element, config);
                this.setVisibility(element, true);
            });
        }

        Core.addElement(element);

        return element;
    }

    /**
     * Remove a widget from bookpoint. Make it hidden and stop receiving events.
     * @param {HTMLElement} element 
     */
    remove(element) {
        //TODO: remove event listeners
        this.setVisibility(element, false);
        Core.removeElement(element);
    }
}

export default new Widget();