/**
 * @module util
 * @example import * as util from './util';
 */

/** return camelCase string as snake_case */
export function snakeCase(stringInput) {
    return stringInput.replace(/(?:^|\.?)([A-Z])/g, function (x, y) { return "_" + y.toLowerCase(); }).replace(/^_/, "");
}

/**
 * return human readable date eg. 'Wed Oct 17 2018'
 * @param {Date} date 
 * @param {object} options
 * @param {string} locale - optional
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString|MDN: Date.prototype.toLocaleDateString()}
 */
export function formatDatePretty(date, options, locale) {
    return new Date(date).toLocaleDateString(locale, options);
}

/** return ISO date without time eg. '2018-10-17'
 * @param {Date} date
*/
export function formatDateISO(date) {
    return new Date(date).toISOString().split("T")[0];
}

/** static structure of common currency symbols keyed by ISO currency code */
const CURRENCY_SYMBOLS = {
    "USD": "$",     //US Dollar
    "EUR": "€",     //Euro
    "GBP": "£",     //British Pound
    "INR": "₹",     //Indian Rupee
    "AUD": "$",     //Australian Dollar
    "CAD": "$",     //Canadian Dollar    
    "SGD": "$",     //Singapore Dollar
    "CHF": "CHF",   //Swiss Franc
    "MYR": "RM",    //Malaysian Ringgit
    "JPY": "¥",     //Japanese Yen
    "CNY": "¥",     //Chinese Yuan Renminbi
}

/** returns currency symbol for given currency code
 * @param {string} currencyCode - 'GBP'
 */
export function getCurrencySymbol(currencyCode) {
    return CURRENCY_SYMBOLS[currencyCode] ? CURRENCY_SYMBOLS[currencyCode] : "£";
}

/** returns departure date as ISO formated string
 * @param {string} arrivalDate - '2018-10-17'
 * @param {string} duration - in days
 */
export function getDepartureDate(arrivalDate, duration) {
    return formatDateISO(new Date(new Date(arrivalDate).getTime() + 24 * 60 * 60 * 1000 * duration));
}

/** Return stay duration in days
 * @param {string} arrivalDate - '2018-10-17'
 * @param {string} departureDate - '2018-10-17'
 */
export function getDuration(arrivalDate, departureDate) {
    const duration = new Date(arrivalDate) - new Date(departureDate);
    return duration / 1000 / 60 / 60 / 24;
}

/** Get tomorrows date in ISO format */
export function getTomorrowsDate() {
    return formatDateISO(new Date().getTime() + 24 * 60 * 60 * 1000);
}