import Core from './core';
import Widget from './widget';
import * as util from './util';
import './theme/style.less';
import { version } from '../package.json';

const DEFAULT_OPTIONS = {
    "hotel_code": "YOUR_HOTEL_CODE",
    "token": "YOUR_BPT_API_TOKEN",
    "widget": true,
    "api_root": "https://bookpoint-dev.navarinoservices.com/api",
    "version": "1.2.3",
    "date_locale": "en-GB",
    "date_format": {
        "weekday": "short",
        "year": "numeric",
        "month": "short",
        "day": "numeric"
    },
    "text": {
        "loading": "Loading",
        "loading_info": "Loading",
        "loading_avail": "Checking availability. Please wait.",
        "loading_book": "Making your booking. Please wait.",
    }
};

const DEFAULT_STATE_SELECTED = {
    "adults": 1,
    "arrival_date": util.formatDateISO(new Date())
}

/**
 * Main entrypoint of Bookpoint - available as global bookpoint
 * @hideconstructor
 * */
class Bookpoint {

    /** Initialise Bookpoint.
     * @param {object} options
     * @example Required:
     * {
     *     "hotel_code": "YOUR_HOTEL_CODE",
     *     "token": "YOUR_BPT_API_TOKEN",
     * }
     * @example Optional:
     * {
     *     "api_root": "https://bookpoint-dev.navarinoservices.com/api".
     *     "version": "1.2.3",
     *     "currency_symbol": "£",
     *     "date_locale": "en-GB",
     *     "date_format": {
     *         "weekday": "short",
     *         "year": "numeric",
     *         "month": "short",
     *         "day": "numeric"
     *     }
     * }
     */
    initialise(userOptions) {
        this.options = {
            ...DEFAULT_OPTIONS,
            ...userOptions
        };
        this.Core = Core;
        this.Core.config = {
            ...this.Core.config,
            ...this.options
        }
        if (this.options.widget === true) {
            this.Widget = Widget;
            this.Widget.init();
        }

        console.debug("Navarino bookpoint " + version + ". API " + this.Core.config.version);

        this.getInfo();
        //TODO: confirm get avail default params
        this.getAvail();
    }

    /** Returns bookpoint {@link Widget} */
    widget() {
        return this.Widget;
    }

    /** Make Info request against Bookpoint API. */
    getInfo() {
        return this.Core.getInfo();
    }

    /** Make Avail request against Bookpoint API using current user selection. */
    getAvail() {
        return this.Core.getAvail();
    }

    /** Set user selection.
     * @param {object} data - {
     * "adults": 1,
     * "arrival_date": "2018-10-09"
     * }
     */
    setSelected(data) {
        let departureDate = new Date(data.arrival_date);
        departureDate.setDate(departureDate.getDate() + data.duration + 1);
        this.Core.setState({
            selected: {
                ...DEFAULT_STATE_SELECTED,
                ...this.Core.state.selected,
                // departure_date: util.formatDateISO(departureDate),
                ...data
            }
        });
    }

    /** Set the stage of a chosen widget
     * @param {string} widgetId - id of target widget
     * @param {string} stage - target stage
     */
    setStage(widgetId, stage) {
        this.Core.setState({
            stage: {
                ...this.Core.state.stage,
                [widgetId]: stage
            }
        });
    }

    /** Override default processor for given element and event.
     * @param {string} elementId
     * @param {string} eventName
     * @param {function} callback - (eventData, state)
    */
    setProcessor(elementId, eventName, callback) {
        if (typeof elementId === 'string' &&
            typeof eventName === 'string' &&
            typeof callback === 'function') {
            if (!this.Core.processor[elementId]) {
                this.Core.processor[elementId] = {}
            }
            this.Core.processor[elementId][eventName] = callback;
        }
    }

    /** Access to bookpoint {@link module:util|utility functions} */
    Util() {
        return util;
    }

    /**
     * Used by templates to format dates in widgets
     * @param {object} options
     * @see {@link module:util.formatDatePretty}
     */
    setDateFormat(options) {
        this.Core.config.date_ormat = options;
    }
}

window.bookpoint = new Bookpoint();