/**
 * Event object included when API request resolves. API data will be stored in "payload" attribute
 * @extends CustomEvent
 */
class APIEvent extends CustomEvent {

    /**
     * Create an APIEvent.
     * @param {string} type - Event type.
     * @param {any} extra - Event payload.
     */
    constructor(type, extra) {
        super(type);
        this.payload = extra;
    }
}

export default APIEvent;